<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="模板名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="模板分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择模板分类">
            <el-option v-for="(item, index) in categoryArr" :key="index" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送渠道：" prop="channel">
          <el-select v-model="channel" placeholder="请选择推送渠道">
            <el-option v-for="(item, index) in channelArr" :key="index" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { CategoryList } from "../../api/tmpl-category/category-list";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      categoryArr: [],
      channelArr: [
        { id: 0, name: "系统消息" },
        { id: 1, name: "短信消息" },
        { id: 2, name: "海报弹窗" },
      ],
    };
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    // 获取分类选择列表
    getCategoryList() {
      CategoryList({ exclude_id: "" })
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    channel: {
      get() {
        return this.form.channel == -1 ? "" : this.form.channel;
      },
      set(val) {
        this.form.channel = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>