<template>
  <div>
    <div class="list-page">
      <list-filter :filterForm="filterForm" :uploadFilter="ok" />
      <list-tabs
        v-model="filterForm.tab"
        :isLoading="loading"
        :tabs="tabArr"
        @tab-click="handleClick"
      >
      </list-tabs>
      <div class="list-content" v-loading="loading">
        <div class="list-btn">
          <el-button
            v-if="filterForm.tab === 'normal'"
            type="primary"
            size="small"
            @click="handleAddTmpl"
            >新增
          </el-button>
          <el-button
            v-if="filterForm.tab === 'deleted'"
            type="success"
            size="small"
            @click="handleReduct"
            >还原
          </el-button>
          <el-button type="danger" size="small" @click="handleDel"
            >删除</el-button
          >
        </div>
        <el-table
          :data="tbody"
          class="thead-light"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <!-- 勾选-->
          <el-table-column fixed type="selection" width="55"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-button
                  v-if="filterForm.tab === 'deleted'"
                  size="small"
                  @click="ReductRow(scope.row)"
                  type="text"
                >
                  还原
                </el-button>
                <el-button
                  v-if="filterForm.tab === 'normal'"
                  size="small"
                  @click="EditRow(scope.row)"
                  type="text"
                >
                  编辑
                </el-button>
                <el-button
                  size="small"
                  @click="deleteRow(scope.row)"
                  type="text"
                  >删除
                </el-button>
              </el-button-group>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(th, index) in thead"
            :key="index"
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :sortable="th.sortable"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- 普通 -->
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
  </div>
</template>

<script>
import listFilter from "../../components/Template/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import {
  templateList,
  softDelete,
  Delete,
  putBack,
} from "../../api/template/template-list";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 80 },
        { label: "模版名称", prop: "name", minWidth: 150 },
        { label: "推送渠道", prop: "channel_text", minWidth: 130 },
        { label: "模版分类", prop: "category_name", minWidth: 130 },
        // { label: "模版类型", prop: "status_check_text", minWidth: 100 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //企业名称
        channel: -1, // 推送渠道
        category_id: "", // 模板分类
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "new",
      },
      current_check_type: 0,
      ids: [], // 不通过组件绑定数组
      openReason: false,
      tbody: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取审核列表
    getTemplateList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      templateList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 新增模板
    handleAddTmpl() {
      this.$router.push({
        name: "templateDetail",
        params: { type: "add", id: 0 },
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 切换tab栏
    handleClick(e) {
      console.log(e.name);
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //企业名称
          channel: -1, // 推送渠道
          category_id: "", // 模板分类
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getTemplateList(this.filterForm);
    },
    // 点击编辑
    EditRow(row) {
      this.$router.push({
        name: "templateDetail",
        params: { type: "edit", id: row.id },
      });
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ ids: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ ids: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ ids: [row.id] });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ ids: [row.id] });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ ids: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ ids: [row.id] });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getTemplateList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getTemplateList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getTemplateList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getTemplateList(pageData);
    },
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.filterForm, ...this.$route.query }
      : this.filterForm;
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab;
    this.getTemplateList(requestData); //获取列表数据
  },
  components: { listFilter, Pagination },
};
</script>

<style lang="scss" scoped>
.list-image {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>
