import api from "@/base/utils/request";

// 获取审核列表
export const templateList = data => {
  return api({
    url: "/admin/blessing/tmpl/index",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/blessing/tmpl/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/blessing/tmpl/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/blessing/tmpl/putBack",
    method: "post",
    data
  });
};
